<template>
  <nav class="navbar navbar-expand-lg bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="/">Familia</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll"
              aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse d-flex justify-content-end" id="navbarScroll">
        <form class="d-flex">
          <div class="btn btn-primary" @click="signIn">SignIn</div>
        </form>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  mounted() {
    this.checkLoggedIn = this.$store.getters['auth/checkLoggedIn'];
  },
  data() {
    return {
      checkLoggedIn: this.$store.getters['auth/checkLoggedIn']
    }
  },
  methods: {
    async signOut() {
      await this.$router.push({path: '/'});
      sessionStorage.clear();
    },
    async signIn() {
      await this.$router.push({path: 'auth/signin'});
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
