<template>
    <form>
        <div class="form-row">
            <div class="col">
                <label class="form-label">First name</label>
                <input type="text" class="form-control" placeholder="First name"
                       aria-describedby="emailHelp" v-model="user.firstname">
            </div>
            <div class="col">
                <label class="form-label">Last name</label>
                <input type="text" class="form-control" placeholder="Last name"
                       aria-describedby="emailHelp" v-model="user.lastname">
            </div>
        </div>
        <div class="form-row">
            <div class="col">
                <label class="form-label">Year of birth</label>
                <input type="date" class="form-control" placeholder="Email address"
                       aria-describedby="emailHelp" v-model="user.yearBirth" max="01/02/2020">
            </div>
        </div>
        <div class="form-row">
            <label class="form-label">Email address</label>
            <input type="email" class="form-control" placeholder="Email address"
                   aria-describedby="emailHelp" v-model="user.email">
        </div>
        <div class="form-row">
            <label class="form-label">Password</label>
            <div>
                <!--        TODO: Refactor this to component"-->
                <div v-if="!passwordVisible" class="input-group mb-3">
                    <input type="password" class="form-control" placeholder="Password" v-model="user.password"
                           minlength="6"
                           maxlength="16">
                    <div class="input-group-append">
                        <span class="input-group-text" @click="togglePasswordVisible"><i
                                class="bi bi-eye-slash-fill"> </i></span>
                    </div>
                </div>
                <div v-if="passwordVisible" class="input-group mb-3">
                    <input type="text" class="form-control" placeholder="Password" v-model="user.password" minlength="6"
                           maxlength="16">
                    <div class="input-group-append ">
                        <span class="input-group-text" @click="togglePasswordVisible"><i
                                class="bi bi-eye-fill"></i></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <label class="form-label">Confirm Password</label>
            <div>
                <div v-if="!passwordVisible" class="input-group mb-3">
                    <input type="password" class="form-control" placeholder="Confirm Password"
                           v-model="user.confirmPassword"
                           minlength="6" maxlength="16">
                    <div class="input-group-append">
                        <span class="input-group-text" @click="togglePasswordVisible"><i
                                class="bi bi-eye-fill"></i></span>
                    </div>
                </div>
                <div v-if="passwordVisible" class="input-group mb-3">
                    <input type="text" class="form-control" placeholder="Confirm Password"
                           v-model="user.confirmPassword"
                           minlength="6" maxlength="16">
                    <div class="input-group-append ">
                        <span class="input-group-text" @click="togglePasswordVisible"><i
                                class="bi bi-eye-slash-fill"></i></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="terms" required v-model="user.terms">
                <label class="form-check-label" for="terms">
                    Yes, I understand and agree to the <a href="/legal/terms-of-use.pdf" target="_blank">Terms of
                    Use</a>
                    and
                    <a href="/legal/privacy-policy.pdf" target="_blank">Privacy Policy</a>
                </label>
            </div>
        </div>
        <div class="form-row form-actions">
            <div class="mt-3 d-flex flex-column">
                <div class="btn btn-primary" @click="emitCreateAccountEmailAndPassword">Continue
                </div>
            </div>
            <auth-navigation-switch :active-links="activeLinks"></auth-navigation-switch>
        </div>
    </form>

</template>

<script>
import AuthNavigationSwitch from "@/components/auth/AuthNavigationSwitch";
import {validatePassword} from "@/utils/common";

export default {
    name: "SignUpForm",
    components: {AuthNavigationSwitch},
    data() {
        return {
            token: null,
            passwordVisible: false,
            activeLinks: {
                signIn: true,
                register: false,
                resetPassword: true
            },
            user: {
                firstname: "",
                lastname: "",
                email: "",
                password: "",
                yearBirth: "",
                confirmPassword: "",
                terms: false,
                verified: false,
                role: "user"
            }
        }
    },
    methods: {
        setTokenFromRoute() {
            this.token = this.$route.query.token
        },
        validateYearOfBirth() {
            let yearOfBirth = new Date(this.user.yearBirth).getFullYear();
            let currentYear = new Date().getFullYear();
            if (currentYear - yearOfBirth < 18) {
                this.$toast.error("You must be at least 18 years old to register!");
                return false;
            }
            return true;
        },
        togglePasswordVisible() {
            this.passwordVisible = !this.passwordVisible;
        },
        setActiveView(view) {
            this.$store.commit("setActivePageView", view)
        },
        confirmPasswordViability() {
            let passwordValidateErrors = validatePassword(this.user.password, this.user.confirmPassword);
            if (passwordValidateErrors.length > 0) {
                this.$toast.error(passwordValidateErrors.join("\n"));
                return false
            }
            return true
        },
        confirmAcceptedTermsAndConditions() {
            if (this.user.terms === false) {
                this.$toast.error("You must agree before submitting!");
                return false;
            }
            return true;
        },
        clearFormData() {
            this.user = {}
        },
        async emitCreateAccountEmailAndPassword() {
            if (this.confirmPasswordViability() && this.confirmAcceptedTermsAndConditions() && this.validateYearOfBirth()) {
                if (this.token) {
                    this.$emit("create-account-email-and-password", this.user, this.token)
                } else {
                    this.$emit("create-account-email-and-password", this.user)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.icon-padding {
  padding: 0.375rem 0.75rem;
}

.input-group-append {
  .input-group-text {
    height: 100%;
    font-size: .75em;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
