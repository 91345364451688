<template>
    <div class="home">
        <auth-nav-bar></auth-nav-bar>
        <div class="d-flex flex-column justify-content-center m-4">
            <div class="d-flex justify-content-between">
                <div class="mb-2">
                    <div class="btn btn-primary" @click="viewTree">VIEW TREE</div>
                </div>
                <h2>List for {{ family.name }}'s family</h2>
                <div class="mb-2" v-if="action==='view'">
                    <div class="btn btn-primary" @click="openAddFamilyMemberModal">Add Family Member</div>
                </div>
            </div>
            <hr>
            <div>
                <div class="d-flex justify-content-center">
                    <add-family-member-modal @add-family-member="addFamilyMember"
                                             :potential-parents="this.familyMembers"
                                             @close-add-family-member="hideAddEditParts"/>
                    <edit-family-member-modal @edit-family-member="updateFamilyMember" :prop-edit-member="editMember"
                                              :prop-potential-parents="familyMembers"
                                              @close-edit-family-member="hideAddEditParts"/>
                </div>

                <div v-if="action==='view'">
                    <family-members-list :familyMembers="familyMembers"
                                         @delete-family-member="deleteFamilyMember"
                                         @edit-family-member="showEditFamilyMember"
                                         @invite-family-member="showInviteFamilyMemberModal"
                                         @add-relationship="showAddFamilyRelationshipModal"
                                          @edit-relationship="showEditFamilyRelationshipModal"
                    ></family-members-list>


                </div>
                <invite-family-member-modal @invite-family-member="inviteFamilyMember"
                                            :prop-family-member="inviteMember"
                                            @close-invite-family-member="hideAddEditParts" :prop-family="family"/>

                <add-family-relationship-modal @add-relationship="addRelationship"
                                               @close-add-relationship="hideModal('add-family-relation-modal')"
                                               :prop-member="relationMember"
                                               :prop-relations="relationshipMembers"/>

                <edit-family-relationship-modal @edit-relationship="editRelationship"
                                                @close-edit-relationship="hideModal('edit-family-relation-modal')"
                                                :prop-member="relationMember"
                                                :prop-relations="relationships"/>


            </div>


        </div>
    </div>
</template>

<script>
import AuthNavBar from "@/components/navigation/AuthNavBar";
import {hideModal, showModal} from "@/utils/common";
import AddFamilyMemberModal from "@/components/modals/AddFamilyMemberModal";
import FamilyMembersList from "@/components/family/FamilyMembersList";
import EditFamilyMemberModal from "@/components/modals/EditFamilyMemberModal";
import InviteFamilyMemberModal from "@/components/modals/InviteFamilyMemberModal";
import {generateHash} from "@/utils/HashUtil";
import AddFamilyRelationshipModal from "@/components/modals/AddFamilyRelationshipModal.vue";
import EditFamilyRelationshipModal from "@/components/modals/EditFamilyRelationshipModal.vue";

export default {
    name: "FamilyMembersTableView",
    components: {
        EditFamilyRelationshipModal,
        AddFamilyRelationshipModal,
        InviteFamilyMemberModal, EditFamilyMemberModal, FamilyMembersList, AddFamilyMemberModal, AuthNavBar
    },
    data() {
        return {
            familyMembers: [],
            family: {},
            editMember: {},
            action: 'view',
            inviteMember: {},
            relationMember: {},
            relationshipMembers: [],
            relationships:{}
        }
    },
    mounted() {
        this.setFamily()
        this.setFamilyMembers()

    },
    methods: {
        hideModal,
        async setFamilyMembers() {
            this.familyMembers = []
            await this.$store.dispatch('members/getFamilyMembers', this.$route.params.id).then(familyMembers => {
                familyMembers.forEach(member => {
                    const updatedMember = {...this.$store.state.defaultFamilyMember, ...member}
                    this.familyMembers.push(updatedMember)
                })
            })
        },

        async setFamily() {
            await this.$store.dispatch('family/getFamily', this.$route.params.id).then(family => {
                this.family = family
            })
        },
        async updateFamilyMember(familyMember) {
            familyMember.familyId = this.$route.params.id
            delete familyMember.listedParents
            await this.$store.dispatch('members/updateMember', familyMember);
            this.closeEditFamilyMemberModal()
            await this.setFamilyMembers()
            this.$toast.success('Family member updated successfully');
            this.action = 'view'
        },
        async deleteFamilyMember(member) {
            await this.$store.dispatch('members/deleteMember', member.id)
            await this.deleteFamilyRelation(member)
            this.$toast.warning('Family deleted successfully');
            await this.getFamilyMembers()
        },
        async addFamilyMember(familyMember) {
            familyMember.familyId = this.$route.params.id
            delete familyMember.listedParents
            await this.$store.dispatch('members/addMember', familyMember);
            this.closeAddFamilyMemberModal()
            await this.setFamilyMembers()
            this.action = 'view'
            this.$toast.success('Family member created successfully');
        },
        async deleteFamilyRelation(familyMember) {
            familyMember.familyId = this.$route.params.id
            if (familyMember.parents && familyMember.parents.length > 0) {
                familyMember.parents.forEach(parent => {
                    this.$store.dispatch('relations/deleteMemberRelation', {
                        id: familyMember.id + parent.id
                    })
                })
            }
        },

        async addRelationship(member) {
            await this.$store.dispatch('members/updateMember', member)
            hideModal('add-family-relationship-modal');
            this.$toast.success('Family member updated successfully');
        },
        openAddFamilyMemberModal() {
            showModal('add-family-member-modal')
        },

        closeAddFamilyMemberModal() {
            hideModal('add-family-member-modal');
        },
        showAddFamilyRelationshipModal(member) {
            this.relationMember = member
            this.relationshipMembers = this.familyMembers.filter(familyMember => familyMember.id !== member.id)
            showModal('add-family-relationship-modal')
        },


        showEditFamilyMember(member) {
            this.$store.commit('members/setEditFamilyMember', member)
            this.$store.commit('members/setPotentialParents', this.familyMembers)
            this.editMember = member
            showModal('edit-family-member-modal')

        },
        hideAddEditParts() {
            this.action = 'view'
        },
        closeEditFamilyMemberModal() {
            hideModal('edit-family-member-modal');
        },
        showInviteFamilyMemberModal(member) {
            //if under 18, then invite
            let diff = Math.abs(new Date() - new Date(member.dateOfBirth));
            let age = Math.floor((diff / (1000 * 3600 * 24)) / 365.25);
            if (age < 18) {
                this.$toast.error('You cannot invite a member who is not over 18 years old');
            } else {
                showModal('invite-family-member-modal');
                this.inviteMember = member

            }
        },
        inviteFamilyMember(family, member) {
            let adminId = generateHash(member.email)
            let familyId = family.id
            let id = `${familyId}-${adminId}`
            this.$store.dispatch('admin/addFamilyAdmin', {id, familyId, adminId})
            this.$store.dispatch('email/sendFamilyInvite', {member, familyId})
            this.$toast.success('Invitation sent successfully');
            hideModal('invite-family-member-modal');
        },
        viewTree() {
            let familyId = this.$route.params.id
            this.$router.push({name: 'family-members-tree', params: {id: familyId}})
        },

        showEditFamilyRelationshipModal(member) {
            this.relationMember = member
            let relationships = {}
            if (member.fid) {
                relationships.father = this.familyMembers.find(familyMember => familyMember.id === member.fid)
            }
            if (member.mid) {
                relationships.mother = this.familyMembers.find(familyMember => familyMember.id === member.mid)
            }
            if (member.pids){
                let partners = []
                member.pids.forEach(pid => {
                    partners.push(this.familyMembers.find(familyMember => familyMember.id === pid))
                })
                relationships.partners=partners
            }
            this.relationships = relationships
            showModal('edit-family-relationship-modal')
        },
        editRelationship(member) {
            this.$store.dispatch('members/updateMember', member)
            hideModal('edit-family-relationship-modal');
            this.$toast.success('Family member updated successfully');
        }


    }
}
</script>

<style scoped>

</style>
