<template>
  <div class="modal centered" id="invite-family-member-modal" data-bs-backdrop="static" tabindex="-1"
       aria-labelledby="add-family-modal" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Invite family member</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label for="name">Email Address</label>
              <input type="email" class="form-control" placeholder="Email address" v-model="invite.email">
            </div>
            <div class="form-group">
              <label for="description">Message</label>
              <textarea type="text" class="form-control" placeholder="Message(optional)"
                        v-model="invite.message"> </textarea>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" @click="sendInvite">Send</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InviteFamilyMemberModal",
  props: {
    propFamily: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      family: {},
      invite: {
        email: '',
        message: '',
        createOn: new Date().toISOString(),
        createdBy: this.$store.state.user.id,
      }
    }
  },
  watch: {
    propFamily: function (newVal) {
      this.family = newVal;
    }
  },
  methods: {
    validateRequired() {
      if (this.invite.email === '') {
        this.$toast('Email address is required');
        return false;
      }
      return true;
    },
    async sendInvite() {
      if (this.validateRequired()) {
        this.$emit('invite-family-member', this.family, this.invite);
      }
    }
  }
}
</script>

<style scoped>

label {
  font-weight: bold;
  float: left;
  margin-top: 2px;
}

</style>
