<template>
    <div class="modal" id="edit-family-member-modal" tabindex="-1" role="dialog" data-bs-backdrop="static">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Edit family member</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-group">
                            <label for="name">Firstname</label>
                            <input type="text" class="form-control"  placeholder="Enter firstname"
                                   v-model="member.firstname">
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="form-group mr-1">
                                <label for="name">Middle name</label>
                                <input type="text" class="form-control"  placeholder="Enter middle name"
                                       v-model="member.middlename">
                            </div>
                            <div class="form-group ml-1">
                                <label for="name">Lastname</label>
                                <input type="text" class="form-control"  placeholder="Enter last name"
                                       v-model="member.lastname">
                            </div>
                        </div>
                        <div class="form-group ml-1">
                                <label for="name">Phone number</label>
                                <input type="number" class="form-control"  placeholder="Phone number"
                                       v-model="member.phone" maxlength="12" minlength="10">
                            </div>
                        <div class="form-group ml-1">
                                <label for="name">Email</label>
                                <input type="email" class="form-control"  placeholder="Email"
                                       v-model="member.email">
                            </div>
                        <div class="d-flex justify-content-between">
                            <div class="form-group">
                                <label for="member">Sex/Gender</label>
                                <select class="form-select w-100" aria-label="Default select example"
                                        v-model="member.gender">
                                    <option v-for="gender in genders" :value="gender.id" :key="gender.id">
                                        {{ gender.name }}
                                    </option>
                                </select>
                            </div>
                            <div>
                                <label for="member">Living status</label>
                                <select class="form-select" aria-label="Default select example"
                                        v-model="member.livingStatus">
                                    <option v-for="living in livingStatus" :value="living.id" :key="living.id">
                                        {{ living.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group ml-1 flex-fill" v-if="member.livingStatus==='alive'">
                                <label for="name">Year of birth</label>
                                <input type="text" class="form-control" id="name" placeholder="Year of birth"
                                       v-model="member.yearBirth" maxlength="4" minlength="4" max="2030">
                            </div>
                            <div class="form-group ml-1 flex-fill" v-if="member.livingStatus !== 'alive'">
                                <label for="name">Year of death</label>
                                <input type="text" class="form-control" id="name" placeholder="Year of death"
                                       v-model="member.yearDeath" maxlength="4" minlength="4" max="2030">
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="form-group">
                                <label for="name">Tribe</label>
                                <input type="text" class="form-control" id="name" placeholder="Tribe"
                                       v-model="member.tribe">
                            </div>
                            <div class="form-group">
                                <label for="name">Clan</label>
                                <input type="text" class="form-control" id="name" placeholder="Clan"
                                       v-model="member.clan">
                            </div>
                            <div class="form-group">
                                <label for="name">Ethnicity</label>
                                <select class="form-select" aria-label="Default select example"
                                        v-model="member.ethnicity">
                                    <option v-for="ethnicity in ethnicities" :value="ethnicity.name"
                                            :key="ethnicity.id">
                                        {{ ethnicity.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div class="form-group flex-fill">
                                <label for="name">Address</label>
                                <input type="text" class="form-control" id="name" placeholder="Address/location"
                                       v-model="member.location">
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="description">Notes</label>
                            <textarea type="text" class="form-control" id="description" placeholder="Enter description"
                                      v-model="member.notes"> </textarea>
                        </div>

                        <div class="form-group d-flex flex-column align-items-baseline mb-5">
                            <label for="description">Files</label>
                            <div>
                                <ul class="list-group col-12">
                                    <li v-for="file in member.files" :key="file.id" class="list-group-item col-12">
                                        {{ file.name }}
                                        <div class="list-icon">
                                            <i class="bi bi-x-octagon-fill" @click="removeFile(file)"></i>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="hideModal('edit-family-member-modal')">Close</button>
                    <button type="button" class="btn btn-primary" @click="updateFamilyMember">Save changes</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {hideModal} from "@/utils/common";


export default {
    name: "AddFamilyMemberModal",
    props: {
        propPotentialParents: {
            type: Array,
            required: false
        },
        propListedParents: {
            type: Array,
            required: false
        },
        propEditMember: {
            type: Object,
            required: false
        },
    },
    data() {
        return {
            potentialParents: [],
            selectedParentRelationship: "Father",
            countries: [],
            cities: [],
            states: [],
            ethnicities: [
                {id: "white", name: "White or Caucasian"},
                {id: "black", name: "Black or African American"},
                {id: "asian", name: "Asian"},
                {id: "hispanic", name: "Hispanic"},
                {id: "native_american", name: "Native American"},
                {id: "other", name: "Other"}
            ],
            selectedParent: {},
            livingStatus: [
                {
                    id: "alive",
                    name: "Alive"
                },
                {
                    id: "deceased",
                    name: "Deceased"
                },
            ],
            genders: [
                {
                    id: "male",
                    name: "Male"
                },
                {
                    id: "female",
                    name: "Female"
                }
            ],
            member: {...this.$store.state.defaultEditFamilyMember, updatedBy: this.$store.state.user.user.uid},
        }
    },
    async mounted() {
        this.member = this.$store.getters["members/getEditFamilyMember"]
        this.potentialParents = this.$store.getters['members/getPotentialParents']
    },
    watch: {
        propPotentialParents: function (newVal) {
            this.potentialParents = newVal
        },
        propListedParents: function (newVal) {
            this.member.listedParents = newVal
        },
        propEditMember: function (newVal) {
            this.member = newVal
            this.member.listedParents = []
        }
    },
    methods: {
        hideModal,
        removeFile(file) {
            this.member.files = this.member.files.filter(f => f.id !== file.id)
        },
        validateRequired() {
            if (this.member.firstname === '') {
                this.$toast('Firstname is required');
                return false;
            }
            if (this.member.lastname === '') {
                this.$toast('Lastname is required');
                return false;
            }
            if (this.member.gender === '') {
                this.$toast('Gender is required');
                return false;
            }
            if (parseInt(this.member.yearBirth) > new Date().getFullYear()) {
                this.$toast(`Date of birth is required to be less than ${new Date().getFullYear()}`);
                return false;
            }
            if (parseInt(this.member.yearBirth).toString().length !== 4) {
                this.$toast('Date of birth is required (size 4))');
                return false;
            }
            if (this.member.yearBirth === '' || this.member.yearBirth === null && this.member.livingStatus === 'alive') {
                this.$toast('Date of birth is required if alive');
                return false;
            }
            return true;
        },
        resetMember() {
            this.member = this.$store.state.defaultFamilyMember
        },
        async updateFamilyMember() {
            if (this.validateRequired()) {
                this.$emit('edit-family-member', this.member);
                this.resetMember();

            }
        },
    }
}
</script>

<style scoped>

label {
    font-weight: bold;
    float: left;
    margin-top: 2px;
}

.list-group {
    margin-top: 10px;
}

.list-group-item {
    padding: 5px;
    font-size: 12px;
    text-align: left;
    font-weight: bold;
}

.list-icon {
    float: right;
    cursor: pointer;
    color: red;
    font-size: 1.2rem;
}

.modal-footer {
    display: flex;
    justify-content: space-between;
}

</style>
