import store from "@/store/index.js"
import router from "@/router";

export const routeGuard = (to, from, next) => {

    let user = sessionStorage.getItem('user')
    if (user) {
        store.commit('auth/setCurrentUser', JSON.parse(user))
        store.commit('auth/setLoggedIn', true)
    }
    const auth = sessionStorage.getItem('loggedIn')

    const fn = () => {
        if (auth) {
            return next();
        }
        router.push({name: "SignIn"})
    };
    if (!auth) {
        return fn();
    }
    return fn()
};
