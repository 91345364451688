<template>
  <div>
  </div>
</template>

<script>
import AccountsService from "@/services/api/account";
export default {
  name: "VerifyAccount",
  mounted() {
    this.verifyAccount()
  },
  methods: {
    verifyAccount() {
      let id = this.$route.query.token
      if (id) {
        AccountsService.updateAccount({id: id, verified: true})
        this.$toast.success("Your account has been verified. You can now sign in.")
        this.$router.push({name: "SignIn"})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
