class Auth {
    constructor() {
        this.tokenUrl = "https://us-central1-lister-1234.cloudfunctions.net/get_auth_token";
    }

    async getToken(data) {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let raw = JSON.stringify(data);
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        let res = await fetch(this.tokenUrl, requestOptions)
        return await res.json()
    }
}

export default new Auth()
