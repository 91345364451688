let Hashes = require('jshashes')

function generateHash(string) {
    let salt = process.env.VUE_APP_HASHING_SALT
    return new Hashes.SHA512().hex_hmac(salt, string)
}


function getStringHash(string) {
    return generateHash(string)
}

export {
    getStringHash,
    generateHash
};
