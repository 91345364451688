<template>
  <div class="d-flex justify-content-between">
    <div
        class="btn btn-link"
        @click="setActiveView('signup')"
        v-if="activeLinks.register"
    >
      Create an account
    </div>
    <div
        class="btn btn-link"
        @click="setActiveView('signin')"
        v-if="activeLinks.signIn"
    >
      Sign in
    </div>
    <div
        class="btn btn-link"
        @click="setActiveView('reset-password')"
        v-if="activeLinks.resetPassword"
    >
      Reset password
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthNavigationSwitch",
  props: {
    activeLinks: {
      type: Object,
      required: true,
      default() {
        return {
          signIn: true,
          register: false,
          resetPassword: false
        }
      }
    }
  },
  methods: {
    setActiveView(view) {
      this.$store.commit("setActiveMenuView", view)
      this.$router.push({path: view})
    }
  }
}
</script>

<style scoped>

</style>
