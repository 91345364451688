import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AuthView from "@/views/AuthView";
import SignInForm from "@/components/auth/SignInForm";
import SignUpForm from "@/components/auth/SignUpForm";
import ResetPassword from "@/components/auth/ResetPassword";
import {routeGuard} from "@/router/routeGuard";
import VerifyAccount from "@/components/auth/VerifyAccount";
import DashboardView from "@/views/DashboardView";
import FamilyMembersTableView from "@/views/FamilyMembersTableView";
import FamiliesListView from "@/views/FamiliesListView";
import ProfileView from "@/views/ProfileView";
import AccountSettings from "@/components/profile/AccountSettings";
import ProfileContent from "@/components/profile/ProfileContent";
import ChangePassword from "@/components/profile/ChangePassword";
import FamilyMembersTreeView from "@/views/FamilyMembersTreeView";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/auth',
        component: AuthView,
        children: [
            {
                name: 'SignIn',
                path: 'signin',
                component: SignInForm,
            },
            {
                name: 'SignUp',
                path: 'signup',
                component: SignUpForm,
            },
            {
                name: 'ResetPassword',
                path: 'reset-password',
                component: ResetPassword,
            },
            {
                name: 'VerifyAccount',
                path: 'verify-account',
                component: VerifyAccount,
            }

        ]

    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardView,
        beforeEnter: routeGuard
    },

    {
        path: '/family/:id/members/tree',
        name: 'family-members-tree',
        component: FamilyMembersTreeView,
        beforeEnter: routeGuard
    },
    {
        path: '/families',
        name: 'families-list',
        component: FamiliesListView,
        beforeEnter: routeGuard
    },
    {
        path: '/family/:id/members/table',
        name: 'family-members-table',
        component: FamilyMembersTableView,
        beforeEnter: routeGuard
    },
    {
        path: '/account',
        name: 'account',
        component: ProfileView,
        beforeEnter: routeGuard,
        children: [
            {
                name: 'AccountSettings',
                path: '/:id/settings',
                component: AccountSettings,
                beforeEnter: routeGuard
            },
            {
                name: 'ChangePassword',
                path: '/:id/change-password',
                component: ChangePassword,
                beforeEnter: routeGuard
            },
            {
                name: 'Profile',
                path: '/:id/profile',
                component: ProfileContent,
                beforeEnter: routeGuard
            }]
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
