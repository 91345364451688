<template>
  <div>
    <div class="dropbox">
      <input type="file" multiple :name="uploadFieldName"
             @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
             class="input-file">
      <p v-if="isInitial">
      </p>
      <p v-if="isSaving">
        Selected {{ fileCount }} files...
      </p>
    </div>
    <div class="error">
      <ul class="list-group">
        <li v-for="error in fileErrors" :key="error">
          {{ error }}
        </li>
      </ul>
    </div>
  </div>

</template>

<script>
import {v4 as uuidv4} from 'uuid';

export default {
  name: "BaseFileUpload",
  props: {
    uploadFieldName: {
      type: String,
      required: true
    },
    initialText: {
      type: String,
      required: false,
      default: "Drag and drop files here or click to select files"
    },
    fileSizeLimit: {
      type: Number,
      required: false,
      default: 10
    },
  },
  data() {
    return {
      isInitial: true,
      isSaving: false,
      fileCount: 0,
      fileErrors: [],
      filesData: []
    }
  },
  methods: {
    checkFileSize(file) {
      const size = 1024 * 1024 * this.fileSizeLimit;
      return file.size > size;
    },
    convertFileToDataUrl(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          resolve(event.target.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      });
    },
    filesChange(fieldName, files) {
      this.isInitial = false
      this.isSaving = true
      //Check file size and return error if too large
      for (let i = 0; i < files.length; i++) {
        let file = files.item(i)
        if (this.checkFileSize(file)) {
          this.fileErrors.push(`${file.name}is too large. Please upload a file less than ${this.fileSizeLimit} MB.`)
        } else {
          this.convertFileToDataUrl(file).then((dataUrl) => {
            this.filesData.push({
              id: uuidv4(),
              name: file.name,
              dataUrl: dataUrl
            })
          })
        }
      }
      if (this.fileErrors.length <= 0) {
        this.$emit('files-uploaded', this.filesData)
      }
    }
  }
}
</script>

<style scoped>
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: #e0edff;
  color: dimgray;
  padding: 10px 10px;
  min-height: 50px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.1em;
  text-align: center;
  padding: 50px 0;
}

</style>
