// import {generateHash} from "@/utils/HashUtil";
// //
// // generateHash


import DatabaseDAO from "@/services/base/DatabaseDAO";

class FamilyAdmins {
    constructor() {
        this.recordUrl = `family-admins`
    }

    async createFamilyAdmin(data) {
        return await DatabaseDAO.addRecord(data, this.recordUrl)
    }

    async getFamilyAdmins (adminId) {
        //filter by adminId
        let familyAdmins = Object.values(await DatabaseDAO.getRecords(this.recordUrl))
        return familyAdmins.filter(familyAdmin => familyAdmin.adminId === adminId)
    }

}

export default new FamilyAdmins();
