class EmailService {
    constructor() {
        this.from_email = "info@familiamoja.com";
        this.host = "familiamoja.com";
    }

    async sendPasswordReset(email, token) {
        let dynamic_data = {
            "email": email,
            "template_id": 4385357,
            "subject": "Password reset",
            "dynamic_template_data": {
                "token": token,
                "host": `https://${this.host}`,
                "account_name":"Familia Moja"
            }
        }

        return await this.sendEmail(email, dynamic_data);
    }

    async sendWelcomeEmail(email, token) {
        let dynamic_data = {
            "email": email,
            "template_id": "pxkjn41z5j9lz781",
            "subject": "Verify your account",
            "dynamic_template_data": {
                "link": `https://${this.host}/verify-email?token=${token}`,
                "account_name":"Familia Moja"
            }
        }

        return await this.sendEmail(email, dynamic_data);
    }

    async sendInviteEmail(email, message, token) {
        let dynamic_data = {
            "email": email,
            "template_id": "o65qngk2068gwr12",
            "subject": "You have been invited to join Familia Moja",
            "dynamic_template_data": {
                "message": message,
                "link": `https://${this.host}/auth/signup?token=${token}`,
                "account_name":"Familia Moja"
            }
        }
        return await this.sendEmail(email, dynamic_data);
    }


    async sendEmail(to, dynamic_data) {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let body = JSON.stringify(dynamic_data)
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: body,
            redirect: 'follow',
            mode: 'no-cors'
        };

        let res = await fetch("https://us-central1-lister-1234.cloudfunctions.net/send_email_template", requestOptions)
        return res;
    }

}

export default new EmailService();
