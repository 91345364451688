import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'
import '@/assets/css/bootstrap.css'
import Toast, {POSITION} from "vue-toastification";
import 'bootstrap-icons/font/bootstrap-icons.css'
import "vue-toastification/dist/index.css";

const toastOptions = {
    position: POSITION.TOP_CENTER
};


Vue.use(Toast, toastOptions);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
