<template>
    <table class="table table-striped">
        <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Description</th>
            <th scope="col">Location</th>
            <th scope="col">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(family, index) in families" :key="family.id">
            <th scope="row">{{ index + 1 }}</th>
            <td>{{ family.name }}</td>
            <td>{{ family.description }}</td>
            <td>{{ family.location }}</td>
            <td>
                <button class="btn btn-primary" @click="editFamily(family)">Edit</button>
                <button class="btn btn-outline-primary" @click="viewFamily(family, 'table')">View Table</button>
                <button class="btn btn-outline-primary" @click="viewFamily(family, 'tree')">View Tree</button>
                <button class="btn btn-danger" @click="deleteFamily(family)">Delete</button>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: "FamilyList",
    props: {
        families: {
            type: Array,
            required: true
        },
    },
    methods: {
        editFamily(family) {
            this.$emit('edit-family', family)
        },
        viewFamily(family, mode) {
            this.$emit('view-family', family, mode)
        },
        deleteFamily(family) {
            let deleteFamily = confirm('Are you sure you want to delete this family?');
            if (deleteFamily) {
                this.$emit('delete-family', family)
            }

        }
    }
}
</script>

<style scoped>

</style>
