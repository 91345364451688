<template>
    <div>
        <table class="table table-striped" aria-description="A table showing list of family members">
            <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Gender</th>
                <th scope="col">Notes</th>
                <th scope="col">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(member, index) in familyMembers" :key="member.id">
                <th scope="row">{{ index + 1 }}</th>
                <td>{{ member.firstname.toUpperCase() }} {{ member.middlename.toUpperCase() || "" }} {{
                    member.lastname.toUpperCase()
                    }}
                </td>
                <td>{{ member.gender === 'male' ? 'M' : 'F' }}

                </td>
                <td>{{ member.notes }}</td>
                <td>
                    <span data-bs-toggle="dropdown" aria-expanded="false"><i class="bi bi-list icon-large"></i></span>

                    <ul class="dropdown-menu">
                        <li><a class="dropdown-item" @click="editMember(member)">Edit Member</a></li>
                        <li><a class="dropdown-item" @click="addRelationship(member)">Add relation</a></li>
                        <li><a class="dropdown-item" @click="inviteMember(member)">Make admin</a></li>
                        <li><a class="dropdown-item text-danger" @click="editRelationship(member)">Edit relation</a>
                        </li>
                        <li><a class="dropdown-item text-bg-danger" @click="deleteMember(member)">Remove
                            member</a></li>
                    </ul>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

</template>

<script>
export default {
    name: "FamilyMembersList",
    props: {
        familyMembers: {
            type: Array,
            required: true
        },
    },
    methods: {
        editMember(member) {
            this.$emit('edit-family-member', member)
        },
        deleteMember(member) {
            let deleteFamily = confirm('Are you sure you want to delete this family member?');
            if (deleteFamily) {
                this.$emit('delete-family-member', member)
            }
        },
        inviteMember(member) {
            this.$emit('invite-family-member', member)
        },
        addRelationship(member) {
            this.$emit('add-relationship', member)
        },
        editRelationship(member) {
            this.$emit('edit-relationship', member)
        }

    }
}
</script>

<style scoped>
.list-group {
    margin-top: 10px;
}

.list-group-item {
    padding: 5px;
    font-size: 12px;
    text-align: left;
    font-weight: bold;
}

button {
    height: 35px;
    font-size: 11px;
}

td {
    font-size: 12px;
    text-align: left;
}

th {
    font-size: 12px;
    text-align: left;
}

.icon-large {
    font-size: 2rem;
    color: cornflowerblue;

}

</style>
