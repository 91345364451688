<template>
    <div class="modal" id="add-family-relationship-modal" tabindex="-1" role="dialog" data-bs-backdrop="static">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add family relationship</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-group">
                            <label for="name">Name </label>
                            <input type="text" class="form-control w-100" id="name" placeholder="Enter first name"
                                   v-model="fullname" disabled="disabled">
                        </div>
                        <div class="form-group">
                            <label>Relation</label>
                            <select class="form-select" aria-label="Default select example"
                                    v-model="selectedRelation">
                                <option v-for="relative in relations" :value="relative" :key="relative.id">
                                    {{ relative.firstname.toUpperCase() }} {{ relative.lastname.toUpperCase() }} ~
                                    ({{ relative.gender.toUpperCase() }})
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <div>
                                <label for="member">Type</label>
                                <select class="form-select" aria-label="Default select example"
                                        v-model="selectedRelationship">
                                    <option>Father</option>
                                    <option>Mother</option>
                                    <option>Spouse</option>
                                </select>
                            </div>
                            <div v-if="selectedRelationship==='Spouse'">
                                <label for="member">Marriage</label>
                                <select class="form-select" aria-label="Default select example"
                                        v-model="selectedMarriage">
                                    <option>Married</option>
                                    <option>Divorced</option>
                                    <option>Seperated</option>
                                </select>

                            </div>

                        </div>
                        <div class="form-group">
                            <label for="description">Notes</label>
                            <textarea type="text" class="form-control" id="description" placeholder="Enter description"
                                      v-model="member.notes"> </textarea>
                        </div>

                    </form>
                </div>
                <div class="modal-footer mt-4">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            @click="hideModal('add-family-relationship-modal')" aria-label="Close">Close
                    </button>
                    <button type="button" class="btn btn-primary" @click="addRelationship">Save changes</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {hideModal} from "@/utils/common";

export default {
    name: "AddFamilyRelationshipModal",
    components: {},
    props: {
        propRelations: {
            type: Array,
            required: false
        },
        propMember: {
            type: Object,
            required: false
        }
    },
    computed: {
        fullname() {
            return `${this.propMember.firstname} ${this.propMember.middlename} ${this.propMember.lastname}`
        }
    },
    data() {
        return {
            selectedRelationship: "Father",
            selectedRelation: {},
            selectedMarriage: "Married",
            member: this.propMember,
            relations: this.propRelations
        }
    },
    watch: {
        propRelations: function (newVal) {
            this.relations = newVal;
        },
        propMember: function (newVal) {
            this.member = newVal;
        }
    },
    mounted() {
        this.member = this.propMember;
        this.relations = this.propRelations;
    },
    methods: {
        hideModal,
        prepareRelation(member, relationId, isSpouse = false) {
            if (isSpouse) {
                if (Object.hasOwn(member, "pids")) {
                    // Add to list if not exists
                    if (!member.pids.includes(relationId)) {
                        member.pids.push(relationId)
                    }
                } else {
                    member.pids = [relationId]
                }
                member.marriage = this.selectedMarriage

            } else {
                if (this.selectedRelationship === "Father") {
                    member.fid = relationId
                } else {
                    member.mid = relationId
                }
            }

            return member;

        },
        addRelationship() {
            let preparedMember = this.prepareRelation(this.member, this.selectedRelation.id, false);
            if (this.selectedRelationship === "Spouse") {
                let preparedRelation = this.prepareRelation(this.selectedRelation, this.member.id, true);
                this.$emit('add-relationship', preparedRelation);
            }
            this.$emit('add-relationship', preparedMember);
        }
    }
}
</script>

<style scoped>

label {
    font-weight: bold;
    float: left;
    margin-top: 2px;
}

.list-group {
    margin-top: 10px;
}

.list-group-item {
    padding: 5px;
    font-size: 12px;
    text-align: left;
    font-weight: bold;
}

.list-icon {
    float: right;
    cursor: pointer;
    color: red;
    font-size: 1.2rem;
}

.form-group-files {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.form-group {
    margin: 5px !important;
}

.modal-footer {
    display: flex;
    justify-content: space-between;
}

</style>
