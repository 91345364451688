import { render, staticRenderFns } from "./BaseFileUpload.vue?vue&type=template&id=189d8e92&scoped=true&"
import script from "./BaseFileUpload.vue?vue&type=script&lang=js&"
export * from "./BaseFileUpload.vue?vue&type=script&lang=js&"
import style0 from "./BaseFileUpload.vue?vue&type=style&index=0&id=189d8e92&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "189d8e92",
  null
  
)

export default component.exports