<template>
    <div class="home">
        <auth-nav-bar></auth-nav-bar>

        <div class="d-flex justify-content-between m-3">
            <div class="mb-2">
                <div class="btn btn-primary" @click="viewTable">VIEW TABLE</div>
            </div>
            <h2>Tree for {{ family.name }}'s family</h2>

        </div>
        <hr>
        <div id="tree" ref="tree">
        </div>
    </div>

</template>

<script>

import FamilyTree from '@balkangraph/familytree.js'
import AuthNavBar from "@/components/navigation/AuthNavBar.vue";

export default {

    name: 'FamilyMembersTreeView',
    components: {AuthNavBar},
    data() {
        return {
            familyMembers: [{
                "firstname": "John",
                "middlename": "Kilonzi",
                "lastname": "Kitonyo",
                "yearBirth": "1988",
                "yearDeath": "",
                "gender": "male",
                "location": "Massachusetts ",
                "tribe": "",
                "ethnicity": "",
                "dateOfBirth": "",
                "mid": "",
                "fid": "",
                "pids": ["6e249055-4236-4032-81c7-2514f93cad32"],
                "clan": "",
                "files": [],
                "notes": "",
                "livingStatus": "alive",
                "createdOn": "2023-07-02T13:58:02.640Z",
                "city": "",
                "country": "",
                "createdBy": "5d47d84ea039c2a53d691609c5657cdf7a7719252f73d8420639ba015937016ae0c67b3d29226ac8eaff72eb086bdcbf14db0cd788f034096bbffd581a76abcf",
                "email": "qwerty@wertyu.com",
                "familyId": "00df984f-9ff1-4a91-99d6-eed36b02f716",
                "id": "1ccebd14-05e8-4546-88ad-ec4c0032956b",
                "marriage": "Married",
                "phone": "123456789",
                "state": ""
            }, {
                "firstname": "EDAH",
                "middlename": "WAIRI",
                "lastname": "MUNYUA",
                "yearBirth": "1990",
                "yearDeath": "",
                "gender": "female",
                "location": "",
                "tribe": "",
                "ethnicity": "",
                "dateOfBirth": "",
                "mid": "",
                "fid": "",
                "pids": ["1ccebd14-05e8-4546-88ad-ec4c0032956b"],
                "clan": "",
                "files": [],
                "notes": "",
                "livingStatus": "alive",
                "createdOn": "2023-07-02T16:13:01.837Z",
                "createdBy": "5d47d84ea039c2a53d691609c5657cdf7a7719252f73d8420639ba015937016ae0c67b3d29226ac8eaff72eb086bdcbf14db0cd788f034096bbffd581a76abcf",
                "email": "jjjj@jnkfjkf.com",
                "familyId": "00df984f-9ff1-4a91-99d6-eed36b02f716",
                "id": "6e249055-4236-4032-81c7-2514f93cad32",
                "marriage": "Married",
                "phone": "5565"
            }, {
                "firstname": "JOJO",
                "middlename": "",
                "lastname": "KILON",
                "yearBirth": "2020",
                "yearDeath": "",
                "gender": "male",
                "location": "",
                "tribe": "",
                "ethnicity": "",
                "dateOfBirth": "",
                "mid": "",
                "fid": "",
                "pids": [],
                "clan": "",
                "files": [],
                "notes": "",
                "livingStatus": "alive",
                "createdOn": "2023-07-02T16:13:01.837Z",
                "familyId": "00df984f-9ff1-4a91-99d6-eed36b02f716",
                "id": "bea887d5-6720-4e58-8471-e835103b4741",
                "phone": "3456789"
            }, {
                "firstname": "JASON",
                "middlename": "",
                "lastname": "KILONZI",
                "yearBirth": "2015",
                "yearDeath": "",
                "gender": "male",
                "location": "",
                "tribe": "",
                "ethnicity": "",
                "dateOfBirth": "",
                "mid": "",
                "fid": "",
                "pids": [],
                "clan": "",
                "files": [],
                "notes": "",
                "livingStatus": "alive",
                "createdOn": "2023-07-02T16:13:01.837Z",
                "createdBy": "5d47d84ea039c2a53d691609c5657cdf7a7719252f73d8420639ba015937016ae0c67b3d29226ac8eaff72eb086bdcbf14db0cd788f034096bbffd581a76abcf",
                "familyId": "00df984f-9ff1-4a91-99d6-eed36b02f716",
                "id": "d71b7202-0631-454f-87e8-f76a00e09018",
                "phone": "46789"
            }],
            familyTree: null,
            family: {}
        }
    },

    methods: {
        familyTreeSetup(id, members) {
            this.familyTree = new FamilyTree(id, {
                // nodeTreeMenu: true,
                enableSearch: false,
                miniMap: true,
                nodes: members,
                showXScroll: true,
                showYScroll: true,
                // nodeMenu: {
                //     details: {text: "Details"},
                //     edit: {text: "Edit"},
                //     add: {text: "Add"},
                //     remove: {text: "Remove"},
                //     myMenuItem: {
                //         text: "My node menu Item", onClick: function (){
                //         }
                //     }
                // },
                linkBinding: {
                    link_field_0: "createdOn"
                },
                toolbar: {
                    layout: true,
                    zoom: true,
                    fit: true,
                    expandAll: false,
                    fullScreen: true
                },
                nodeBinding: {
                    field_0: "firstname",
                    field_1: "lastname"

                },
                menu: {
                    pdf: {text: "Export PDF"},
                    csv: {text: "Export CSV"},
                }
            });
        },
        async getFamilyMembers() {
            this.familyMembers = await this.$store.dispatch('members/getFamilyMembers', this.$route.params.id)
        },
        async getFamily() {
            await this.$store.dispatch('family/getFamily', this.$route.params.id).then(family => {
                this.family = family
            })
        },
        viewTable() {
            let familyId = this.$route.params.id
            this.$router.push({name: 'family-members-table', params: {id: familyId}})
        }
    },

    async mounted() {
        await this.getFamily()
        await this.getFamilyMembers()
        this.familyTreeSetup(this.$refs.tree, this.familyMembers)
        this.familyTree.on('click', function (sender, args) {
            console.log(args)
        })
        this.familyTree.onUpdateNode((args) => {
            console.log(args.updateNodesData.length)
        })
    }
}
</script>

<style scoped>
#tree {
    width: 100%;
    height: 100%;
}
</style>