<template>
    <div class="home">
        <auth-nav-bar></auth-nav-bar>
        <div class="d-flex flex-column justify-content-center m-4">
            <div class="d-flex justify-content-end mb-1">
                <div class="btn btn-primary" @click="openAddFamilyModal">Add Family</div>
            </div>
            <div>
                <family-list :families="families" @delete-family="deleteFamily"
                             @edit-family="showEditFamily" @view-family="viewFamily"></family-list>
            </div>
            <add-family-modal @add-family="addFamily"/>
            <edit-family-modal @edit-family="updateFamily" :edit-family="editFamily"/>
        </div>
    </div>
</template>

<script>
import AuthNavBar from "@/components/navigation/AuthNavBar";
import {hideModal, showModal} from "@/utils/common";
import AddFamilyModal from "@/components/modals/AddFamilyModal";
import FamilyList from "@/components/family/FamilyList";
import EditFamilyModal from "@/components/modals/EditFamilyModal";

let uuid = require('uuid');

export default {
    name: "FamiliesListView",
    components: {EditFamilyModal, FamilyList, AddFamilyModal, AuthNavBar},
    data() {
        return {
            families: [],
            editFamily: {},
            userId: this.$store.state.user.id
        }
    },
    mounted() {
        this.getFamiliesByAdminId()
    },
    methods: {
        async getFamiliesByAdminId() {
            this.families = await this.$store.dispatch('family/getFamilies', this.$store.state.user.user.uid)
        },
        viewFamily(family, mode) {
            if (mode === 'table') {
                this.$router.push({name: 'family-members-table', params: {id: family.id}})
            } else {
                this.$router.push({name: 'family-members-tree', params: {id: family.id}})
            }

        },

        async addDefaultFamilyMember(familyId) {
            let familyMemberId = this.$store.state.user.user.uid
            let familyMember = await this.$store.dispatch("account/getAccount", familyMemberId)
            familyMember.familyId = familyId
            // remove password from family member
            delete familyMember.password
            delete familyMember.token
            delete familyMember.verified
            delete familyMember.role

            await this.$store.dispatch('members/addMember', familyMember);
        },

        async addFamily(family) {
            family.id = uuid.v4()
            await this.$store.dispatch('family/createFamily', family);
            await this.addDefaultFamilyMember(family.id)
            this.$toast.success('Family created successfully');
            this.closeAddFamilyModal()
            await this.getFamiliesByAdminId() // refresh the list
        },
        async deleteFamily(family) {
            await this.$store.dispatch('family/deleteFamily', family.id)
            this.$toast.warning('Family deleted successfully');
            await this.getFamiliesByAdminId() // refresh the list
        },
        async updateFamily(family) {
            await this.$store.dispatch('family/updateFamily', family)
            this.$toast.success('Family updated successfully');
            this.closeEditFamilyModal()
            await this.getFamiliesByAdminId() // refresh the list
        },
        async checkIfAdminCanAddFamily() {
            let familyMemberId = this.$store.state.user.user.uid
            let familyMember = await this.$store.dispatch("account/getAccount", familyMemberId)
            if (familyMember.firstname === "" || familyMember.lastname === "" || familyMember.email === "") {
                this.$toast.info(' You cannot create a new family, without filling your profile details. Go to MY ACCOUNT >> PROFILE to fill your details', {
                    duration: 10000
                });
                return false
            }
            return true
        },
        showEditFamily(family) {
            this.editFamily = family
            showModal('edit-family-modal')
        },
        async openAddFamilyModal() {
            if (await this.checkIfAdminCanAddFamily()) {
                showModal('add-family-modal')
            }
        },
        closeAddFamilyModal() {
            hideModal('add-family-modal');
        },
        closeEditFamilyModal() {
            hideModal('edit-family-modal');
        }

    }
}
</script>

<style scoped>

</style>
