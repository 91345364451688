<template>
  <div>
    <div>
      <auth-nav-bar></auth-nav-bar>
    </div>
    <div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="#">ACCOUNT</a></li>
          <li class="breadcrumb-item active" aria-current="page">{{ page }}</li>
        </ol>
      </nav>
    </div>
    <router-view @update-breadcrumb="setBreadcrumb"></router-view>
  </div>
</template>

<script>
import AuthNavBar from "@/components/navigation/AuthNavBar";

export default {
  name: "ProfileView",
  components: {AuthNavBar},
  data() {
    return {
      page: this.$router.currentRoute.name
    }
  },
  methods: {
    setBreadcrumb(page) {
      this.page = page;
    }
  }

}
</script>

<style scoped>

</style>