<template>
    <div class="modal" id="edit-family-relationship-modal" tabindex="-1" role="dialog" data-bs-backdrop="static">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Remove family relationship</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="form-group">
                            <label for="name">Name </label>
                            <input type="text" class="form-control w-100" id="name" placeholder="Enter first name"
                                   v-model="fullname" disabled="disabled">
                        </div>
                        <div class="d-flex flex-column">
                            <div>
                                <label class="float-start">Relationships</label>
                            </div>

                            <ul class="list-group">
                                <li class="list-group-item d-flex justify-content-between" v-if="relations.mother">
                                    {{ relations.mother.firstname }}
                                    {{ relations.mother.lastname }}
                                    (Mother)
                                    <div>
                                        <div class="btn btn-sm btn-danger"
                                             @click="removeRelationShip(relations.mother.id, false)">
                                            <i class="bi bi-trash"></i>
                                        </div>
                                    </div>
                                </li>
                                <li class="list-group-item d-flex justify-content-between" v-if="relations.father">
                                    {{ relations.father.firstname }}
                                    {{ relations.father.lastname }}
                                    (Father)

                                    <div>
                                        <div class="btn btn-sm btn-danger"
                                             @click="removeRelationShip(relations.father.id, false)">
                                            <i class="bi bi-trash"></i>
                                        </div>
                                    </div>
                                </li>

                            </ul>
                            <ul class="list-group" v-if="relations.partners.length >0">
                                <li class="list-group-item d-flex justify-content-between"
                                    v-for="partner in relations.partners" :key="partner.id">
                                    {{ partner.firstname }} {{ partner.lastname }} (Spouse)
                                    <div>
                                        <div class="btn btn-sm btn-danger"
                                             @click="removeRelationShip(partner.id, true)">
                                            <i class="bi bi-trash"></i>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    </form>
                </div>
                <div class="modal-footer mt-4">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"
                            @click="hideModal('add-family-relationship-modal')" aria-label="Close">Close
                    </button>
                    <button type="button" class="btn btn-primary" @click="editRelationship">Save changes</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {hideModal} from "@/utils/common";

export default {
    name: "EditFamilyRelationshipModal",
    components: {},
    props: {
        propRelations: {
            type: Object,
            required: false
        },
        propMember: {
            type: Object,
            required: false
        }
    },
    computed: {
        fullname() {
            return `${this.propMember.firstname} ${this.propMember.middlename} ${this.propMember.lastname}`
        },
    },
    data() {
        return {
            selectedRelationship: "Father",
            selectedRelation: {},
            selectedMarriage: "Married",
            member: this.propMember,
            relations: this.propRelations
        }
    },

    watch: {
        propRelations: function (newVal) {
            this.relations = newVal;
        },
        propMember: function (newVal) {
            this.member = newVal;
        }
    },
    mounted() {
        this.member = this.propMember;
        this.relations = this.propRelations;
    },

    methods: {
        hideModal,
        removeRelationShip(relationId, isSpouse = false) {
            let confirm = window.confirm("Are you sure you want to remove this relationship?");
            if (!confirm) {
                return;
            }
            // TODO: remove relationship needs to be updated
            if (isSpouse) {
                this.relations.partners = this.relations.partners.filter(partner => partner.id !== relationId);
                this.member.pids = this.member.pids.filter(pid => pid !== relationId);
                let partner = this.relations.partners.find(partner => partner.id === relationId);
                partner.pids = partner.pids.filter(pid => pid !== this.member.id);
                this.editRelationship(this.member);
                this.editRelationship(partner);
            } else {
                if (this.relations.mother && this.relations.mother.id === relationId) {
                    this.relations.mother = null;
                    this.member.mid = "";
                    this.editRelationship(this.member);
                } else if (this.relations.father && this.relations.father.id === relationId) {
                    this.relations.father = null;
                    this.member.fid = "";
                    this.editRelationship(this.member);
                }
            }
        },
        editRelationship(relative) {
            this.$emit('edit-relationship', relative);
        }
    }
}
</script>

<style scoped>

label {
    font-weight: bold;
    float: left;
    margin-top: 2px;
}

.list-group {
    margin-top: 10px;
}

.list-group-item {
    padding: 5px;
    font-size: 12px;
    text-align: left;
    font-weight: bold;
}

.list-icon {
    float: right;
    cursor: pointer;
    color: red;
    font-size: 1.2rem;
}

.form-group-files {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.form-group {
    margin: 5px !important;
}

.modal-footer {
    display: flex;
    justify-content: space-between;
}

</style>
