<template>
    <nav class="navbar navbar-expand-lg bg-light">
        <div class="container-fluid">
            <a class="navbar-brand" href="/">Familia</a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll"
                    aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse d-flex justify-content-between" id="navbarScroll">
                <div></div>
                <ul class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" style="--bs-scroll-height: 100px;">
                    <li class="nav-item" @click="navigateToPage('families-list')">
                        <a class="nav-link active">Families</a>
                    </li>
                </ul>

                <div class="dropdown">
                    <div class="dropdown-toggle" type="button" data-bs-toggle="dropdown"
                         aria-expanded="false">
                        MY ACCOUNT
                    </div>
                    <ul class="dropdown-menu dropdown-menu-lg-end">
                        <li @click="navigateToPage('Profile')">My Profile</li>
                        <li @click="navigateToPage('AccountSettings')">Settings</li>
                        <li @click="navigateToPage('ChangePassword')">Change Password</li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li class="dropdown-item text-danger" @click="signOut">
                            <div>Sign out</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'AuthNavBar',
    methods: {
        async signOut() {
            await this.$router.push({path: '/'});
            sessionStorage.clear();
        },
        navigateToPage(page) {
            let id = this.$store.state.user.user.uid;
            this.$router.push({name: page, params: {id: id}});
        }
    }
}
</script>
<style scoped lang="less">
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
