<template>
  <div class="container-fluid">
    <auth-nav-bar></auth-nav-bar>

  </div>
</template>
<script>
import AuthNavBar from "@/components/navigation/AuthNavBar";
export default {
  components: {AuthNavBar}
}
</script>
