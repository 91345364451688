import DatabaseDAO from "@/services/base/DatabaseDAO";
import FamilyAdmins from "@/services/api/familyAdmins";

let uuid = require('uuid');

class Family {
    constructor() {
        this.recordUrl = `family`
    }

    async createFamily(data) {
        // call database service to create account
        // return account object

        //generate uuid

        if (data.id === undefined) {
            data.id = uuid.v4()
        }
        //Add creator to family admins
        let familyAdmin = {
            familyId: data.id,
            adminId: data.createdBy,
            id: data.id + data.createdBy
        }
        await FamilyAdmins.createFamilyAdmin(familyAdmin)
        return await DatabaseDAO.addRecord(data, this.recordUrl)
    }

    async getFamily(id) {
        // call database service to get account
        // return account object
        return await DatabaseDAO.getRecord(id, this.recordUrl)
    }

    async updateFamily(data) {
        // call database service to update account
        // return account object
        let dataId = data.id
        return await DatabaseDAO.updateRecord(dataId, this.recordUrl, data)
    }

    async deleteFamily(id) {
        // call database service to update account
        // return account object
        return await DatabaseDAO.deleteRecord(id, this.recordUrl)
    }

    async getFamiliesByAdminId(adminId) {
        let familyAdmins = await FamilyAdmins.getFamilyAdmins(adminId);
        let setFamilyIds = new Set(familyAdmins.map(familyAdmin => familyAdmin.familyId));

        return await Promise.all(
            Array.from(setFamilyIds)
                .map(async familyId => {
                    let family = await this.getFamily(familyId);
                    if (family.name !== undefined) {
                        return family;
                    }
                })
                .filter(Boolean)
        )

    }
}

export default new Family();
