<template>
  <form>
    <div class="form-row">
      <label class="form-label">Email address</label>
      <input type="email" class="form-control" placeholder="Email address"
             aria-describedby="emailHelp" v-model="user.email">
    </div>
    <div class="form-row">
      <please-wait-modal></please-wait-modal>
      <label class="form-label">Password</label>
      <div v-if="!passwordVisible" class="input-group mb-3">
        <input type="password" class="form-control" placeholder="Password" v-model="user.password">
        <div class="input-group-append">
          <span class="input-group-text" @click="togglePasswordVisible"><i class="bi bi-eye-slash-fill"></i></span>
        </div>
      </div>
      <div v-if="passwordVisible" class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Password" v-model="user.password">
        <div class="input-group-append">
          <span class="input-group-text" @click="togglePasswordVisible"><i class="bi bi-eye-fill"></i></span>
        </div>
      </div>
    </div>
    <div class="form-row form-actions">
      <div class="mt-3 d-flex flex-column">
        <div
            @click="emitSignInWithEmailAndPassword"
            class="btn btn-primary"
        >
          Sign in
        </div>
        <auth-navigation-switch
            :active-links="activeLinks"/>
      </div>
    </div>
  </form>
</template>

<script>

import AuthNavigationSwitch from "@/components/auth/AuthNavigationSwitch";
import PleaseWaitModal from "@/components/modals/PleaseWaitModal";

export default {
  name: "SignInForm",
  components: {PleaseWaitModal, AuthNavigationSwitch},
  data() {
    return {
      passwordVisible: false,
      user: {},
      activeLinks: {
        signIn: false,
        register: true,
        resetPassword: true
      }
    }
  },
  methods: {
    togglePasswordVisible() {
      this.passwordVisible = !this.passwordVisible;
    },
    clearFormData() {
      this.user = {}
    },
    emitSignInWithEmailAndPassword() {
      this.$emit("sign-with-email-and-password", this.user);
      this.clearFormData();
    }
  }
}
</script>

<style lang="scss" scoped>
.input-group-append {
  .input-group-text {
    height: 100%;
    font-size: .75em;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}


</style>
