<template>
  <div class="home">
    <nav-bar></nav-bar>
    <div>
      <!--      Add image from src/assets-->
      <div class="mt-5">
        <img :src="require(`@/assets/img/family-tree.png`)" height="600" width="1200" alt="Familia moja application">
        <h1>
          Welcome to Familia moja
        </h1>
        <p>
          Familia moja is a web application that allows you to create a family tree and add members to it. You can also
          share your family tree with others.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/navigation/NavBar";

export default {
  name: 'HomeView',
  components: {
    NavBar,
  },
  computed: {
    image() {
      return "../../assets/img/family-tree.jpg"
    }
  }
}
</script>
