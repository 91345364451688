import { render, staticRenderFns } from "./SignUpForm.vue?vue&type=template&id=3bdfc17d&scoped=true&"
import script from "./SignUpForm.vue?vue&type=script&lang=js&"
export * from "./SignUpForm.vue?vue&type=script&lang=js&"
import style0 from "./SignUpForm.vue?vue&type=style&index=0&id=3bdfc17d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bdfc17d",
  null
  
)

export default component.exports