<template>
  <form>
    <div class="form-row">
      <label class="form-label">Email address</label>
      <input type="email" class="form-control" placeholder="Email address"
             aria-describedby="emailHelp" v-model="user.email">
    </div>
    <div class="form-row form-actions">
      <div class="mt-3 d-flex flex-column">
        <button class="btn btn-primary" @click="resetPassword">Continue
        </button>
      </div>
      <auth-navigation-switch
          :active-links="activeLinks" />
    </div>
  </form>
</template>

<script>

import AuthNavigationSwitch from "@/components/auth/AuthNavigationSwitch";
export default {
  name: "ResetPassword",
  components: {AuthNavigationSwitch},
  data() {
    return {
      user: {
        email: ""
      },
      activeLinks:{
        signIn: true,
        register: true,
        resetPassword: false
      }
    }
  },
  methods: {
    resetPassword() {
      if (this.user.email) {
        this.$store.commit("setActiveMenuView", 'signin')
        this.$toast.info("We have sent an email to you with reset instructions")
        return
      }
      this.$toast.error("Please enter a valid email")

    },
    setActiveView(view) {
      this.$store.commit("setActiveMenuView", view)
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
