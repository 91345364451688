<template>
  <div>
    <div class="d-flex align-items-center flex-column">
      <form class="col-md-6">
        <div class="d-flex justify-content-start">
          <div v-if="account.image" >
            <label class="form-label">Profile Photo</label>
            <img :src="account.image" alt="Profile photo" class="img-fluid rounded" height="350px">
          </div>
          <div v-if="!account.image">
            <div>
              <label class="form-label">Profile Photo</label>
              <input class="btn btn-outline-primary col-12" type="file" placeholder="Change profile picture" Change
                     Profile Photo @change="onFileChange($event.target.name, $event.target.files)">
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between flex-fill flex-grow">
          <div class="mb-3">
            <label for="email">First name</label>
            <input class="form-control" type="text" v-model="account.firstname">
          </div>
          <div class="mb-3">
            <label for="email">Middle name</label>
            <input class="form-control" type="text" v-model="account.middlename">
          </div>
        </div>

        <div class="mb-3">
          <label for="email">Last name</label>
          <input class="form-control" type="text" v-model="account.lastname">
        </div>
        <div class="mb-3">
          <label for="email">Phone number</label>
          <input class="form-control" type="number" v-model="account.phone">
        </div>
        <div class="d-flex justify-content-between">
          <div class="mb-3">
            <label>Date of birth</label>
            <input class="form-control" type="date" v-model="account.dateOfBirth">
          </div>
          <div class="form-group">
            <label for="member">Sex/Gender</label>
            <select class="form-select w-100" aria-label="Default select example" v-model="account.gender">
              <option v-for="gender in genders" :value="gender.id" :key="gender.id">
                {{ gender.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="form-group">
            <label for="name">Tribe</label>
            <input type="text" class="form-control" id="name" placeholder="Tribe"
                   v-model="account.tribe">
          </div>
          <div class="form-group">
            <label for="name">Clan</label>
            <input type="text" class="form-control" id="name" placeholder="Clan"
                   v-model="account.clan">
          </div>
          <div class="form-group">
            <label for="name">Ethnicity</label>
            <select class="form-select" aria-label="Default select example" v-model="account.ethnicity">
              <option v-for="ethnicity in ethnicities" :value="ethnicity.name" :key="ethnicity.id">
                {{ ethnicity.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="form-group flex-fill">
            <label for="name">Country</label>
            <select class="form-select" aria-label="Default select example" v-model="account.country"
                    @change="getStatesByCountry" @click="getStatesByCountry">
              <option v-for="country in countries" :value="country" :key="country.isoCode">
                {{ country.name }} {{ country.flag }}
              </option>
            </select>
          </div>
          <div class="form-group flex-fill">
            <label for="name">State/Province/County</label>
            <select class="form-select" aria-label="Default select example" v-model="account.state"
                    @change="getCitiesByStateCountry">
              <option v-for="state in states" :value="state" :key="state.isoCode">
                {{ state.name }}
              </option>
            </select>
          </div>
          <div class="form-group flex-fill">
            <label for="name">City</label>
            <select class="form-select" aria-label="Default select example" v-model="account.city">
              <option v-for="city in cities" :value="city" :key="city.isoCode">
                {{ city.name }}
              </option>
            </select>
          </div>

        </div>
        <div class="d-flex justify-content-end mt-5">
          <button class="btn btn-primary" type="button" @click="updateProfile">Update</button>
        </div>


      </form>
    </div>
  </div>
</template>

<script>
import {City, Country, State} from "country-state-city";

export default {
  name: "ProfileContent",
  components: {},
  data() {
    return {
      account: {
        image: null,
        country: "",
        state: "",
        city: "",
      },
      genders: [
        {
          id: "male",
          name: "Male"
        },
        {
          id: "female",
          name: "Female"
        }
      ],
      ethnicities: [
        {id: "white", name: "White or Caucasian"},
        {id: "black", name: "Black or African American"},
        {id: "asian", name: "Asian"},
        {id: "hispanic", name: "Hispanic"},
        {id: "native_american", name: "Native American"},
        {id: "other", name: "Other"}
      ],
      countries: [],
      cities: [],
      states: [],
      country: "",
      fileSizeLimit: 3,
      initialText: "Upload Profile Photo",
      uploadFieldName: 'files',
      fileErrors: []
    }
  },
  async mounted() {
    this.$emit("update-breadcrumb", "My Profile");
    await this.getUserAccountSettings()
    this.countries = Country.getAllCountries()
    await this.getStatesByCountry()
    await this.getCitiesByStateCountry()
  },
  methods: {
    async getUserAccountSettings() {
      let id = this.$store.state.user.user.uid;
      this.account = await this.$store.dispatch('account/getAccount', id);
    },
    async getStatesByCountry() {
      this.states = State.getStatesOfCountry(this.account.country.isoCode)
    },
    async getCitiesByStateCountry() {
      this.cities = City.getCitiesOfState(this.account.country.isoCode, this.account.state.isoCode)
    },
    checkFileSize(file) {
      const size = 1024 * 1024 * this.fileSizeLimit;
      return file.size > size;
    },
    convertFileToDataUrl(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          resolve(event.target.result);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsDataURL(file);
      });
    },
    onFileChange(name, files) {
      for (let i = 0; i < files.length; i++) {
        let file = files.item(i)
        if (this.checkFileSize(file)) {
          this.fileErrors.push(`${file.name}is too large. Please upload a file less than ${this.fileSizeLimit} MB.`)
        } else {
          this.convertFileToDataUrl(file).then((dataUrl) => {
            // this.files.push({
            //   id: uuidv4(),
            //   name: file.name,
            //   dataUrl: dataUrl
            // })
            this.account.image = dataUrl
          })
        }
      }
    },
    updateProfile() {
      this.$store.dispatch('account/updateAccount', this.account)
      this.$toast.success("Profile updated successfully")
    }
  }
}
</script>

<style scoped>
label {
  font-weight: bold;
  float: left;
  margin-top: 2px;
}

</style>