import DatabaseDAO from "@/services/base/DatabaseDAO";
import {generateHash} from "@/utils/HashUtil";
import Auth from "@/services/api/auth";
import EmailService from "@/services/email/emailService";

class AccountsService {
    constructor() {
        this.recordUrl = `accounts`
    }

    async createAccount(accountData) {
        // call database service to create account
        // return account object
        return await DatabaseDAO.addRecord(accountData, this.recordUrl)
    }

    async getAccount(accountId) {
        // call database service to get account
        // return account object
        return await DatabaseDAO.getRecord(accountId, this.recordUrl)
    }

    async updateAccount(accountData) {
        // call database service to update account
        // return account object
        let accountId = accountData.id
        return await DatabaseDAO.updateRecord(accountId, this.recordUrl, accountData)
    }

    async deleteAccount(accountId) {
        // call database service to update account
        // return account object
        return await DatabaseDAO.deleteRecord(accountId, this.recordUrl)
    }

    async getAccountByEmail(email) {
        return await DatabaseDAO.filterRecord(this.recordUrl, {'email': email})
    }

    async signInWithEmailAndPassword(accountData) {
        // call database service to update account
        // return account object
        let email = accountData.email
        let password = accountData.password
        let data = await this.getAccountByEmail(email)
        let account =data[0]
        let hashedPassword = generateHash(password)
        if (hashedPassword === account.password) {
            delete account.password
            delete account.image
            return {...account, ...await Auth.getToken(account)}
        }
    }

    async checkUserExists(email) {
        let user = await this.getAccountByEmail(email)
        return user.length > 0
    }


    async signUpWithEmailAndPassword(accountData) {
        if (await this.checkUserExists(accountData.email)) {
            throw new Error('User already exists')
        }
        let password = accountData.password
        //Hash password
        accountData.password = generateHash(password)
        delete accountData.confirmPassword
        await EmailService.sendWelcomeEmail(accountData.email)
        await this.createAccount(accountData)
    }

}

export default new AccountsService();
