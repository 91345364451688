import * as Bootstrap from "bootstrap";


// Language: javascript
function getModal(id) {
    return new Bootstrap.Modal(document.getElementById(id), {
        keyboard: true,
        backdrop: false
    })
}

export function showModal(id) {
    let modal = getModal(id)
    modal.show()
}

export function hideModal(id) {
    document.getElementById(id).style.display = 'None';
}


export function validatePassword(password, confirmPassword) {
    let errors = [];
    if (password !== confirmPassword) {
        errors.push("Confirm password must be equal to password")
    }
    if (password.length < 8) {
        errors.push("Your password must be at least 8 characters");
    }
    if (password.length > 16) {
        errors.push("Your password must be at most 16 characters");
    }
    if (password.search(/[a-z]/i) < 0) {
        errors.push("Your password must contain at least one letter.");
    }
    if (password.search(/[0-9]/) < 0) {
        errors.push("Your password must contain at least one digit.");
    }
    if (password.search(/[a-z]/) < 0) {
        errors.push("Your password must contain at least one lowercase letter.")
    }
    if (password.search(/[A-Z]/) < 0) {
        errors.push("Your password must contain at least one uppercase letter.")
    }
    return errors
}
