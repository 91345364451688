import DatabaseDAO from "@/services/base/DatabaseDAO";

class Relations {
    constructor() {
        this.recordUrl = `relations`
    }

    async createRelation(data) {
        // call database service to create account
        // return account object
        return await DatabaseDAO.addRecord(data, this.recordUrl)
    }

    async getRelation(id) {
        // call database service to get account
        // return account object
        return await DatabaseDAO.getRecord(id, this.recordUrl)
    }

    async updateRelation(data) {
        // call database service to update account
        // return account object
        let dataId = data.id
        return await DatabaseDAO.updateRecord(dataId, this.recordUrl, data)
    }

    async deleteRelation(data) {
        // call database service to update account
        // return account object
        let dataId = data.id
        return await DatabaseDAO.deleteRecord(dataId, this.recordUrl)
    }

    async getRelations() {
        // call database service to update account
        // return account object
        return Object.values(await DatabaseDAO.getRecords(this.recordUrl))
    }
}

export default new Relations();
