<template>
    <div class="page container-xl" id="auth-view">
        <div class="row m-5 d-flex justify-content-center text-start align-items-center">
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-8 col-xs-12">
                <div class="form mb-5">
                    <div
                            class="alert alert-primary"
                            role="alert"
                            v-if="routeName==='SignIn'"
                    >
                        Please sign in to your account.
                    </div>
                    <div
                            class="alert alert-warning"
                            role="alert"
                            v-if="routeName==='SignUp'"
                    >
                        Please create an account.
                    </div>
                    <router-view @sign-with-email-and-password="signWithEmailAndPassword"
                                 @create-account-email-and-password="createAccountEmailAndPassword"></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {signInWithCustomToken} from "firebase/auth";
import {auth} from "../../firebaseConfig";
import * as uuid from "uuid";

export default {
    name: "AuthView",
    mounted() {
        this.$emit("reloadNavigation")
    },
    computed: {
        routeName() {
            return this.$route.name
        }
    },
    methods: {
        async signWithEmailAndPassword(user) {
            try {
                let account = await this.$store.dispatch("auth/signin", user)
                if (account.verified) {
                    this.$store.commit("auth/setLoggedIn", true);
                    this.$store.commit("auth/setCurrentUser", account);
                    sessionStorage.setItem("loggedIn", true)
                    let token = account.token
                    let userCredential = await signInWithCustomToken(auth, token)
                    sessionStorage.setItem("user", JSON.stringify(userCredential));
                    await this.$router.push({path: "/families"});
                } else {
                    this.$toast.error("Please verify your email address");
                }
            } catch (e) {
                console.log(`${e}`)
                this.$toast.error(`Email or password is incorrect or does not exist`);
            }

        },
        async createAccountEmailAndPassword(user) {
            try {
                user.id = uuid.v4()
                await this.$store.dispatch("auth/signup", user)
                this.$toast.success("Account created successfully");
                await this.$store.dispatch("email/sendWelcomeEmail", {"email": user.email, "id": user.id})
                await this.$router.push({path: "signin"})
            } catch (e) {
                this.$toast.error(e.message);
            }
        }

    }
}
</script>

<style lang="scss" scoped>
.form-row {
  margin: 0 auto 1rem;

  .form-check {
    display: block;
    min-height: 1.5rem;
    padding: 1em 1em 1em 2.2em;
    font-size: .875em;
    background: #f1efef;
  }
}

.form-actions {
  margin: 2rem 0;

  .btn {
    display: block;
    width: auto;
    margin: 0 0 1rem;
  }

  .btn-link {
    margin: 0;
    padding: 0;
    text-align: left;
  }
}
</style>
