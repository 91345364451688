<template>
  <div class="modal" id="edit-family-modal" data-bs-backdrop="static" tabindex="-1"
       aria-labelledby="edit-family-modal" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edit family</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label for="name">Name</label>
              <input type="text" class="form-control" id="name" placeholder="Enter firstname" v-model="family.name">
            </div>
            <div class="form-group">
              <label for="name">Location</label>
              <input type="text" class="form-control" id="name" placeholder="Enter location" v-model="family.location">
            </div>
            <div class="form-group">
              <label for="description">Notes</label>
              <textarea type="text" class="form-control" id="description" placeholder="Enter description"
                        v-model="family.description"> </textarea>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" @click="updateFamily">Save changes</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditFamilyModal",
  props: {
    editFamily: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      genders: [
        {
          id: "male",
          name: "Male"
        },
        {
          id: "female",
          name: "Female"
        },
        {
          id: "non-binary",
          name: "Non-binary"
        }
      ],
      family: {
        name: '',
        description: '',
        location: '',
        updatedOn: new Date().toISOString(),
        updatedBy: this.$store.state.user.id
      }
    }
  },
  watch:{
    editFamily: function (newVal) {
      this.family = newVal
    }
  },
  methods: {
    validateRequired() {
      if (this.family.name === '') {
        this.$toast('Name is required');
        return false;
      }
      return true;
    },
    async updateFamily() {
      if (this.validateRequired()) {
        this.$emit('edit-family', this.family);
      }
    }
  }
}
</script>

<style scoped>

label {
  font-weight: bold;
  float: left;
  margin-top: 2px;
}

</style>
