<template>
  <div>
    <h1>
      Change password
    </h1>
    <div class="d-flex justify-content-center">
      <form class="col-md-6">
        <div>
          <div class="form-row">
            <label class="form-label">Old Password</label>
            <input type="password" class="form-control" placeholder="Old Password"
                   aria-describedby="emailHelp" v-model="user.oldPassword">
          </div>
          <hr>
          <div class="form-row">
            <label class="form-label">New Password</label>
            <input type="password" class="form-control" placeholder="New Password"
                   aria-describedby="emailHelp" v-model="user.password">
          </div>
          <div class="form-row">
            <label class="form-label">Confirm New Password</label>
            <input type="password" class="form-control" placeholder="Confirm New Password"
                   aria-describedby="emailHelp" v-model="user.confirmPassword">
          </div>
        </div>
        <div class="form-row form-actions">
          <div class="mt-3 d-flex flex-column">
            <div class="btn btn-outline-danger" @click="changePassword">Continue
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

</template>

<script>
import {generateHash} from "@/utils/HashUtil";

export default {
  name: "ChangePassword",
  data() {
    return {
      account: {},
      user: {
        oldPassword: "",
        password: "",
        confirmPassword: ""
      }
    }
  },
  async mounted() {
    this.$emit("update-breadcrumb", "Change Password");
    await this.getAccount()
  },
  methods: {
    async getAccount(){
      this.account = await this.$store.dispatch("account/getAccount", this.$route.params.id);
    },
    confirmPasswordViability() {
      if (this.user.password.length < 8) {
        this.$toast.error("The password must be more than 8 characters")
        return false
      }
      if (this.user.password !== this.user.confirmPassword) {
        this.$toast.error("The new password must be the same as the confirm new password")
        return false
      }
      return true
    },
    async changePassword() {
      let oldPassword = generateHash(this.user.oldPassword)
      if (oldPassword !== this.account.password) {
        this.$toast.error("The old password is incorrect")
        return
      }
      if (!this.account.id) {
        this.$toast.error("Unexpected error occurred. Please refresh browser and retry again")
        return
      }
      if (this.confirmPasswordViability()) {
        delete this.user.confirmPassword
        await this.$store.dispatch("account/updateAccount", {
          id: this.account.id,
          password: generateHash(this.user.password)
        })
        this.$toast.success("Password changed successfully")
        await this.$router.push({name: "SignIn"})
      }
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
