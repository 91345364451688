import Account from "@/services/api/account";
import Members from "@/services/api/members";
import Family from "@/services/api/family";
import Relations from "@/services/api/relations";
import FamilyAdmins from "@/services/api/familyAdmins";
import EmailService from "@/services/email/emailService";

export default {
    "auth/signin": async function (context, accountData) {
        return await Account.signInWithEmailAndPassword(accountData)
    },
    "auth/signup": async function (context, accountData) {
        return await Account.signUpWithEmailAndPassword(accountData)
    },
    "account/addAccount": async function (context, accountData) {
        return await Account.createAccount(accountData)
    },
    "account/getAccount": async function (context, accountId) {
        return await Account.getAccount(accountId)
    },
    "account/updateAccount": async function (context, accountData) {
        return await Account.updateAccount(accountData)
    },
    "account/deleteAccount": async function (context, accountId) {
        return await Account.deleteAccount(accountId)
    },
    "members/addMember": async function (context, memberData) {
        return await Members.createMember(memberData)
    },
    "members/getMember": async function (context, memberId) {
        return await Members.getMember(memberId)
    },
    "members/updateMember": async function (context, memberId, memberData) {
        return await Members.updateMember(memberId, memberData)
    },
    "members/deleteMember": async function (context, memberId) {
        return await Members.deleteMember(memberId)
    },
    'members/getFamilyMembers': async function (context, familyId) {
        return await Members.getFamilyMembers(familyId)
    },
    "family/createFamily": async function (context, familyData) {
        return await Family.createFamily(familyData)
    },
    "family/getFamily": async function (context, familyId) {
        return await Family.getFamily(familyId)
    },
    "family/updateFamily": async function (context, familyData) {
        return await Family.updateFamily(familyData)
    },
    "family/deleteFamily": async function (context, familyId) {
        return await Family.deleteFamily(familyId)
    },
    "family/getFamilies": async function (context, userId) {
        return await Family.getFamiliesByAdminId(userId)
    },
    "relations/addMemberRelation": async function (context, relationData) {
        return await Relations.createRelation(relationData)
    },
    "relations/deleteMemberRelation": async function (context, relationData) {
        return await Relations.deleteRelation(relationData)
    },
    "admin/addFamilyAdmin": async function (context, adminData) {
        return await FamilyAdmins.createFamilyAdmin(adminData)
    },
    'email/sendFamilyInvite': async function (context, invite) {
        return await EmailService.sendInviteEmail(invite.member.email, invite.member.message, invite.familyId);
    },
    'email/sendWelcomeEmail': async function (context, welcomeData) {
        return await EmailService.sendWelcomeEmail(welcomeData.email, welcomeData.id);
    }

}
