<template>
  <div>
    <h1>Account Settings</h1>
    <div class="d-flex justify-content-center">
      <form class="col-md-6">
        <div class="d-flex justify-content-start">
          <label>Get notified when a family member is added/removed in a family you manage</label>
          <div>
            <input type="checkbox" v-model="account.notifyFamilyMemberAdded">
          </div>
        </div>


      </form>
    </div>
  </div>
</template>

<script>

export default {
  name: "AccountSettings",
  data() {
    return {
      account: {
        notifyFamilyMemberAdded: false
      },
      genders: [
        {
          id: "male",
          name: "Male"
        },
        {
          id: "female",
          name: "Female"
        }
      ],
      ethnicities: [
        {id: "white", name: "White or Caucasian"},
        {id: "black", name: "Black or African American"},
        {id: "asian", name: "Asian"},
        {id: "hispanic", name: "Hispanic"},
        {id: "native_american", name: "Native American"},
        {id: "other", name: "Other"}
      ],
      countries: [],
      cities: [],
      states: [],
      country: "",
    }
  },
  async mounted() {
  },
  methods: {}
}
</script>

<style scoped>
label {
  font-weight: bold;
  float: left;
  margin-top: 2px;
}

</style>